
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,800;1,300&display=swap');
@import '~@fortawesome/fontawesome-free/css/all.min.css';
:root {
  --gt-primary:  #316CF4;
  --gt-secondary:#EFF9FF;
  --gt-yellow:#FABF12;
  --gt-red: #FF2828;
  --gt-dark-blue: #041D56;  
  --gt-text-primary:#222222;
  --gt-text-secondary:#9B9B9B ;
  --gt-text-green:#39A727 ;
  --gt-text-white:#FFFFFF ;

}

body {
  width: '100%';
  height: '100%';
  background-color: #EFF9FF;
  font-size: 12px;
  color: var(--gt-text-primary);
  font-family: 'Montserrat', sans-serif;
}
h1{
   font-size: 30px;
}
h2{
  font-size: 24px;
}
h3{
  font-size: 16px;
}
h4{
  font-size: 12px;
}
h5{
  font-size: 10px;
}
h6{
  font-size: 8px;
}

.bg-primarycolor {
  background-color: #316cf4
}

.bg-thirdcolor {
  background-color: #fabf12
}


.mob-masterhead,
.item-single-div-mob,
.item-mastr-img-div-mob,
.cart-card-mob,
.cart-tot-mob{
display: none;
}

.favoratenormal{
 color: var(--gt-text-secondary); 
}
.favorateselect{
  color: var(--gt-red); 
 }
.gtt-container {
  max-width: 1200px;
  /* Set a maximum width for the container */
  margin: 0 auto;
  /* Automatically centers the container horizontally */
  padding: 0px;
  text-align: center;
  width: 100%;
  padding-top:75px;

}

.companylogo {
  width: 200px;
  height: 40px;
}

.fixed-background {

  background-size: cover;
  background-position: top center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 650px;
  z-index: -1;
  /* Place the background behind other content */
  background: linear-gradient(180deg, #000532 0%, #004CBD 100%);
}



/***********TEXT BOX SEARCH CSS****************/
.search-form-control {
  display: block;
  width: 100%;
  height: 30px;
  padding: 6px 20px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid var(--gt-primary);
  background: #FFF;
  border-radius: 5px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
}

.earch-form-control:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.full-bg-head{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;  
  position: fixed;
  top: 0;
  left:0;
  background-color: #EFF9FF; /* Optional: You can set the background color as needed */
  z-index: 1000;
  height: 100px;
  text-align: center;

}

.maincategory-head{
  margin-top: 70px;
  min-height: 30px;
  width: 100%;
  background-color: var(--gt-primary);
  display: flex;
  justify-content: center;
  align-items: center;
}
.maincategory-head .maincategory-head-sub {
  max-width: 1200px;
  display: flex;
  justify-content:center;
  align-items: center;
  width: 100%;
}
.maincategory-head .maincategory-head-sub a{
  text-decoration: none;
   margin: 5px;
   font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: #FFF;
  margin-right: 16px;;

}
.masterhead {
  width: 100%;  
  position: fixed;
  top: 0;
  background-color: #EFF9FF  ; /* Optional: You can set the background color as needed */
  z-index: 1000;
  height: 60px;  
  max-width: 1200px;
  display: grid;
  grid-template-columns: 200px 150px auto 250px;
  background-color: var(--gt-secondary);
  padding: 10px;
  width: 100%;

}

.main-body{
  padding-top:75px
}


.masterhead .grid-item{
  background-color: transparent;  
  text-align: center; 
  align-self: center; 
}
.grid-item:first-child {
  justify-self: last baseline;
}

.grid-item:last-child {
  justify-self: end;
}

.masterhead .item {
     
  color: #fff;
  display: flex;
  align-items: center;
  justify-content:center;

}
.masterhead .normal-text a {

  color: #fff;
  text-decoration: none;
  font-size: 12px;
  padding: 10px 20px;

}


.masterhead .icon-name {
  margin-left: auto;
}

.masterhead .icon-name a {

  text-decoration: none;
  color: var(--gt-primary);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  font-size: 12px;
  justify-content: center;
  margin: 10px;
 
}
.masterhead .icon-name input{
  border: 1px solid var(--gt-primary);
  border-right: none;
}

.masterhead .icon-name .gtt-searchbtn{
  
  border: 1px solid var(--gt-primary);
  border-left: none;
  color: var(--gt-primary);
  background-color: var(--gt-text-white);
  
 
}
.masterhead .icon-name .gtt-searchbtn i{
  
 font-size: 12px;;
  
 
}
.masterhead .icon-name i {
  font-size: 20px;
  margin-bottom: 5px;
}

.masterhead .icon-name button {
  font-size: 12px;
  
}

.masterhead .input-container {
  position: relative;
  width: 100%;
  text-align: center;
}

.masterhead  .input-container .icon i {
 font-size: 14px;;
 
}

.menu-item {
  position: relative;
  padding: 10px;
  cursor: pointer; 
  display: inline-block;
}
.submenu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  min-width:  325px;
  min-height: 225px;
  border-radius: 5px;
  margin-top: 16px;;
}

.menu-item:hover .submenu {
  display: block;  
  transform-origin: center;
  transition: transform 0.3s ease;
  

}
.menu-item .submenu:before {
 
  margin-top: 25px;;
  content: "";
  position: absolute;
  bottom: 100%; /* Changed from "top" */
  left:10%;
  transform: translateX(20%);
  width: 0;
  height: 0;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
  border-bottom: 20px solid #fff; /* Changed from "border-top" */
}

.menu-item::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #ffde00;
  bottom:1px; /* Adjust the position of the underline */
  left: 0;
  transform: scaleX(0); /* Initially hidden */
  transform-origin: center;
  transition: transform 0.3s ease;
}

.menu-item:hover {
  
}
.menu-item .submenu .link-menu-div{
  height: 25px;
  text-align: left;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.menu-item .submenu .link-menu-div a{ 
  color: #000;
  font-size: 12px;
  font-weight:600;
  padding: 5px;
  text-align: left;
  align-items: start; 
}
.menu-item .link-menu-div:hover{
  background-color: #ffde00;
}
.menu-item:hover::before {
  transform: scaleX(1); /* Show underline on hover */
}








.slidsection{
  display: flex;
  justify-content: space-between; /* Center horizontally */
  align-items: center;
}

/*****************carousel*******************************/
.gtt-master-carousel{
  width: 60%;
}


.gtt-master-carousel  .gtt-carousel{
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;
}

.gtt-master-carousel  .gtt-carousel .half-picture img{
  max-height:300px;
 
}


.gtt-master-carousel .carousel-control-prev {
  display: none;
}
.gtt-master-carousel .carousel-control-next {
  display: none;
}

.gtt-master-carousel .vl {
  border-left: 1px solid #fff;
  height: 50%;
  position: absolute; 
  margin-left:15%;
 
}


/*****************carousel-two*******************************/

.gtt-carousel-two{
  width:35%;

}


.gtt-carousel-two .card-slid{


  max-height:300px;
  background-color: #fff;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.gtt-carousel-two .react-multiple-carousel__arrow{
 display: none;

}
.gtt-carousel-two :hover  .react-multiple-carousel__arrow{
  display: block; 
 }



 .mob-bottom-menu{
  display: none;
 }
 .mob-bottom-menu-bgdiv{
  display: none;
 }


 


 
 .card-gtt-1{
  width: 100%;
  height:140px ;
  background: #D9D9D9;
  box-shadow: 0px 4px 20px rgba(49, 108, 244, 0.25); 
  border-radius: 15px
}

.card-gtt-1 img{
  width: 100%;
  height: 100%;
   
}
.adslist-master-mob{
  display: none;
}


/*****************carousel-categorybanne*******************************/

.gtt-category-banner-carousel{
  padding-top: 5px;;
  width:100%;
  margin-bottom: 0;
}

.gtt-category-banner-carousel .react-multiple-carousel__arrow{
  display: none;
 
 }
 .gtt-category-banner-carousel:hover .react-multiple-carousel__arrow{
   display: block; 
  }


/***************CATGROY ROUND *****************************/
.category-img-box{

  border: 0px none;
  height: 125px;
  width: 98px;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
}

.category-img-box .catgory-ellipse{

  background-color: #d9d9d9;
  border-radius: 49px;
  height: 98px;
  width: 98px;
 
  display: flex; /* Add display: flex */
  align-items: center; /* Center the image vertically */
  justify-content: center; /* Center the image horizontally */
  margin-bottom: 5px;
  
}


.category-img-box .catgory-ellipse img {
  height: 110px;
  object-fit: cover;
  

}

.category-img-box a{
  display: flex; /* Add display: flex */
  align-items: center; /* Center the image vertically */
  justify-content: center; /* Center the image horizontally */
}


.category-img-box a span {

  text-decoration: none;
  position: absolute;
  bottom:0;
  width: 100%;
  text-align: center;
  color: #000532;
  font-size: 10px;
  font-weight: 700;

 
}
.catgory-show-div .react-multiple-carousel__arrow{
  display: none;
 
 }
 .catgory-show-div:hover .react-multiple-carousel__arrow{
   display: block; 
  }
 
.input-container-mob{
  display: none;
}




/*******************PRODUCTLIST HEAD TAG***************************/


.productlist-head-tag{

  display: flex;
  align-items: center;
  justify-content: first baseline;
  margin-top: 20px;
  padding-left: 2px;

}
.productlist-head-tag .symbol-tag{

  width: 30%;
  height: 32px;
  border-left: 3px solid #ffde00;
  padding: 10px;
  color: #000532;
  text-align: left;
  font-size: 12px;
  font-weight: 600;
  display: flex; /* Add display: flex */
  align-items: center; /* Center the image vertically */
  /* background-color: #66afe9; */
  /* background: linear-gradient(to right, #66afe9, #EFF9FF); */
  
}
.productlist-head-tag .symbol-seemore a{
  text-decoration: none;
}
.productlist-head-tag .symbol-seemore{


  color: #004CBD;
  text-align: right;
  font-size: 12px;
  font-weight: 500;
  display: flex; /* Add display: flex */
  align-items: center; /* Center the image vertically */
  width: 70%;
  justify-content: end;
  padding: 5px;

}


/*******************PRODUCT CARD***************************/



.product-carousel-xl .react-multi-carousel-list{
  background: transparent;
  padding: 10px;


}

.product-card-gtt
{
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 2px 0px #85858640;
  height:350px;
  min-width: 200px;
  padding: 5px;
  margin: 5px; 
}
.product-card-gtt .product-body-gtt
{
  position: relative;
  padding: 5px;
  width: 100%;
}
.product-body-gtt img{

  width: 100%;
  max-height: 200px;
  object-fit: scale-down;
  border-radius: 8px;
  padding: 0;
  margin: 0;
 
}

.product-details-gtt{

  position: relative;
}

.product-body-gtt .addcartbtn{


  text-decoration: none;
  padding: 2px 10px 2px 10px;
  border: 1px solid #316cf4;
  background-color: #004CBD;
  color: #fff; 
  border-radius: 25px;
  font-size: 12px;
  font-weight: 500;

}
.product-body-gtt .addcartbtn:hover{

  border: 1px solid #316cf4;
  color: #004CBD;
  background-color: #fff;
}

.product-body-gtt .addcartbtn img{

   height: 20px;
   width: 20px;
}


.product-body-gtt .product-brand-text{
  text-align: left;
  font-size: 10px;
  font-weight: 500;
  color: #9b9b9b;
  padding-bottom:0px;
  margin: 0 0 5px 0; 
  line-height: 1px;
  
}
.product-body-gtt .product-title {  

  margin: 10px 0;
  color: var(--gt-text-primary);
  text-align: left;
  margin: 5px 0 0 0; 
  white-space: nowrap; /* Prevent text from wrapping to multiple lines */
  overflow: hidden; /* Hide any overflowing content */
  text-overflow: ellipsis; 

}
.product-body-gtt .product-description {
  text-align: left;
  font-size: 10px;
  font-weight: 600;
  color: #9b9b9b;
  margin: 0; 
  padding-bottom:0px;
  height: 30px;
  width: 100%;
  overflow: hidden;
 

}
.product-body-gtt .icon{
 position: absolute;
   /* Position the icon absolutely within the container */
  top: 5px; /* Adjust the top position to place the icon at the desired location */
  right: 1px; /* Adjust the right position to place the icon at the desired location */

  text-align: right; 
  font-size: 22px;
   margin: 0;
}
.product-body-gtt .addcart-icon{
  position: absolute;
    /* Position the icon absolutely within the container */
   top: 5px; /* Adjust the top position to place the icon at the desired location */
   left : 1px; /* Adjust the right position to place the icon at the desired location */
   color: var(--gt-text-green);
   text-align: left; 
   font-size: 22px;
    margin: 0;
 }



.product-body-gtt .product-attribute-gtt{

   
  display: flex; /* Add display: flex */
  align-items:center; /* Center the image vertically */
  justify-content: space-between; /* Center the image horizontally */
  margin:0;
  padding: 0;
  margin-bottom: 5px;


}

.product-body-gtt .product-attribute-gtt .price-tag {
  text-decoration: none;
  text-align: right;
  margin: 0; 
  line-height: 1px;
}
.product-body-gtt .product-attribute-gtt .price-tag .was-price{
  text-align: right;
  font-size: 14px;
  font-weight: 600;
  text-decoration: line-through;
  color: #9b9b9b;
  margin: 0;


}
.product-body-gtt .product-attribute-gtt .price-tag .current-price{

  text-align: right;
  color: #38a626; 
  font-size: 20px;
  font-weight: 600;
  margin: 0;


}
.currency-tag {

  text-align: right;
  color: #38a626; 
  font-size: 12px;
  font-weight: 600;
  margin: 0;
  margin-right: 2px;
  
}
.product-body-gtt .product-attribute-gtt .price-tag .discount-tag {
 
  color: #fff; 
  font-size: 12px;
  font-weight: 600; 
  background-color: #fabf12;
  border-radius: 15px;
  padding: 2px;
  text-align: right;

}

.product-body-gtt .product-attribute-gtt .gtt-facility{

  font-size: 10px;
  font-weight: 600;
  font-style: normal;
  color: #000;
  text-align: left;
  margin: 0;
}


.product-body-gtt .product-attribute-gtt .gtt-facility .gtt-freedelivery{

  font-size: 10px;
  font-weight: 600;
  font-style: italic;
  color: #000;
  margin: 0;
  background: linear-gradient(to right, #ffde00, white);
}




.product-body-gtt .product-attribute-gtt .gtt-facility .gtt-waranty{

  font-size: 10px;
  font-weight: 700;
  font-style: normal;
  color: #000;
  margin-bottom: 2px;
  line-height: 1;
}
.product-body-gtt .product-attribute-gtt .gtt-facility .gtt-waranty .warranty-tag {

  font-size: 10px;
  font-weight: 400;
  font-style: normal;
  
}

.product-body-gtt .product-attribute-gtt p {
  /* Add this rule to remove spacing for <p> tags within .product-attribute-gtt */
  margin: 0;
}

.product-carousel-xl .react-multiple-carousel__arrow{
  display: none;
 
 }
 .product-carousel-xl:hover .react-multiple-carousel__arrow{
   display: block; 
  }
 .img-banner{
  display: flex; /* Add display: flex */
  align-items: center; /* Center the image vertically */
  justify-content: space-between; /* Center the image horizontally */

 
 }
/*********************END OF THE PRODUCT CARD***********************************/

/*********************FOOTER STRAT HERE***********************************/
.gtt-page-bottom{   
  
    padding: 0;
    width: 100%;    
    background-color: #fff;
     
}

.gtt-page-bottom footer{

  max-width: 1200px;
  margin: 0 auto; 
  padding: 0px;
  text-align: center;
  width: 100%;
  
}
.footer-container{
  display: flex;
  width: 100%;
  align-items: left;
  justify-content:space-between;
  color: #222222;
  padding-top: 25px;
  

}
.footer-container  h4{
 
 color: #222222;
 font-size: 12px;
 font-weight: 700;
 text-align: left;

}

.footer-container .content_1 a,
.footer-container .content_2 a,
.footer-container .content_3 a
{
  display: flex;
  flex-direction: column;
  text-align: left; 
  text-decoration: none;
  font-size: 12px;
  font-weight: 500;
  color: #000;
  margin-bottom: 5px;
}

.footer-container .address-div .inline-link{

  display: flex;
  align-items: center; 
  text-decoration: none;
  gap: 5px;
  color: #000;
  margin-bottom:15px;

}

.footer-container .address-div img{

  width: 12px;
  height: 12px;

}

.footer-container .follow-div{

  display: flex; /* Add display: flex */
  align-items:center; /* Center the image vertically */
  justify-content:last baseline; /* Center the image horizontally */
  
  padding: 0;

}
.footer-container .follow-div .inline-link{

  margin: 5px;
}
.footer-design{
  width: 100%;
  border-top: 2px solid #ffde00;
  
}
.footer-design-txt{
  color: #000000;
  font-size: 10px;
  font-weight: 300;
}

.footer-terms{
  display: flex; /* Add display: flex */
  align-items:center; /* Center the image vertically */
  justify-content:space-around; 
  margin-bottom: 20px;
  margin-top: 20px;
}
.footer-terms a {
text-decoration: none;
font-size: 12px;
font-weight: 400;
color: #000000;
padding: 5px;
}



.footer-brand{
  background-color: #000532;
 text-align: center;
}


.gtt-bottombrand{
   
  background-color: #000532;
  width: 100%;
  color: 'white';
  font-size: 12;
  font-weight: '700';
  letter-spacing: 1.28;
  word-wrap: 'break-word';
  text-align: center;
  padding: 5px;
  text-align: center;

}


.gtt-bottombrand{
   
  background-color: #000532;
  width: 100%;
  color: 'white';
  font-size: 12;
  font-weight: '700';
  letter-spacing: 1.28;
  word-wrap: 'break-word';
  text-align: center;
  padding: 5px;
  text-align: center;

}


.brand-multi-slide{

  padding: 8px;

}
.brand-img-box{

  border: 0px none;
  padding: 5px;
  width: 100px;
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}


.brand-multi-slide .react-multiple-carousel__arrow{
  display: none;
 
 }
 .brand-multi-slide:hover .react-multiple-carousel__arrow{
   display: block; 
  }
 
/*********************FOOTER END HERE***********************************/



.gtt-panel{

  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  margin: 5px auto;
}

.gtt-panel .gtt-panel-content{
  padding: 10px;
  text-align: left;
  color: #222222;
  font-size: 14px;
  font-weight: 600;

}
.gtt-panel .leftpane-category {
  font-size: 12px;
  font-weight: 400;
  padding: 5px;
  width: 100%;;
  text-align: left;
  text-decoration: none;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;
  color: #222222;

}
.gtt-panel .leftpane-category:hover{

  font-size: 12px;
  font-weight: 800;
}


.gtt-panel .gtt-panel-content .gtt-hr{
  border: none; /* Remove the default border */
  height: 2px; /* Set the height of the line */
  background-color: #ffde00; /* Set the desired color */
  width: 80%;
  display: flex; 
  align-items: center;
  justify-content: center;
}
.gtt-panel .leftpane-brand{
  font-size: 12px;
  font-weight: 400;
  padding: 5px;
  width: 100%;;
  text-align: left;
  text-decoration: none;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;
  color: #222222;

}
.gtt-panel .leftpane-brand .leftpane-brand-checkbox{
    padding: 5px;
}
.gtt-panel .leftpane-brand .leftpane-brand-checkbox input{
  margin-right: 5px;;
}

.gtt-panel .leftpane-brand .show-more-btn{

  background: none;
  border: none;
  color: #316cf4; /* Change the color as desired */
  font-size: 12px;
  margin-top: 10px;
  cursor: pointer;
  text-decoration: underline;
  display: flex;
  align-items: end;
 
}

.gtt-panel .leftpane-price-range{
  font-size: 12px;
  font-weight: 400;
  padding: 5px;
  width: 100%;;
  text-align: left;
  text-decoration: none;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;
  color: #222222;
}
.gtt-panel .leftpane-price-range button{
  width:30%; 
  font-size: 12px;
}
.gtt-panel-center{

  background-color: transparent;
  border-radius: 10px;  
  max-width: 100%;
  margin: 5px auto;
}
.gtt-panel-center .gtt-panel-content-center{
  
   background-color:#EFF9FF;;
   width: 100%;
}



.gtt-itemshow-div{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}


.pagination-nav{

  background-color: #ffffff;
  border-radius: 26px;
  box-shadow: 0px 4px 10px #316cf440;
  height: 50px;
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.pagination {
  margin: 0;
  padding: 0;
  text-align: center
}
.pagination li {
  display: inline
}
.pagination li a {
  display: inline-block;
  text-decoration: none;
  padding: 5px 10px;
  color: #000
}

/* Active and Hoverable Pagination */
.pagination li a {
  border-radius: 14px;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s    
}
.pagination .disabled span {
 font-size: 18px;
 font-weight: 500;
 color: #004CBD;

}


.pagination li.active a {
  background-color: #004CBD;
  color: #fff;
  border-radius: 14px;
  height: 28px;
  width: 28px;
}
.pagination li a:hover:not(.active) {
  background-color: #ccc;
  color: #fff;
} 
.gtt-itemshow-div select{
  font-size: 12px;
  width: 100%;
}

.gtt-badge-white{
   
  height: 20px;
  background-color: #fff;
  color:#222222;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 400;
  padding: 5px;;
  margin-right: 2px;

}
.gtt-badge-clear{
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
  color: #000532;
}
.gtt-badge-clear:hover{
  cursor: pointer;
  color: #004CBD;
  text-decoration: underline;
}
.left-side-pane{
  display: block;
 }
.gtt-cart{
  position: relative; /* Container for the icon and badge */
    display: inline-block;
}

 .gtt-badge {
     position: absolute;
    top: -2px; /* Adjust this value to position the badge vertically */
    right:0px; /* Adjust this value to position the badge horizontally */
    display: inline-block;
    padding: 2px 6px;
    background-color: #fabf12; /* Background color of the badge */
    color: white; /* Text color of the badge */
    border-radius: 50%;
}



.gtt-subcategory-div
{
  display: flex;
  align-items: center;
}
.gtt-subcategory-container{
  display: flex;
  align-items: center;
  margin: 0 auto;
  overflow: hidden;
  scrollbar-width: thin;
  position: relative;
  
}
.gtt-subcategory-container::-webkit-scrollbar {
  width: 10px;
  height: 5px; /* This might be needed for cross-browser consistency */
}
.gtt-subcategory-container::-webkit-scrollbar-track {
  background-color: #ebebeb;
  border-radius: 10px;
}
.gtt-subcategory-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #6d6d6d; 
}



.gtt-subcategory-container:hover {
  overflow-x: scroll;
}

.gtt-subcategory-content {

  display: flex;
  position: relative; /* Needed for absolute positioning of scroll buttons */
  transition: transform 0.3s; /* Add a smooth transition for content movement */
  scrollbar-color: #000532 #ebebeb; /* thumb color, track color */
  padding-left:25px;
  padding-right: 25px;

}

.gtt-subcategory-content a {
  text-decoration: none;
  font-size: 14px;
  font-weight:400;
  padding: 5px;
   background-color: #fff;
   border: 1px solid #004CBD; 
   border-radius: 5px;
   min-width: 100px;
   margin: 2px;;
  }


  .gtt-subcategory-content a:hover {
   
     background-color: #ffde00;
     border: 1px solid #004CBD;     
     color: #000532;
     font-weight:500;
    }
.gtt-scroll-button {

    cursor: pointer;
}

.gtt-subcategory-container:hover .gtt-scroll-button {
  opacity: 1;
}


.error-page{

  width: 100%;
  min-height: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #000532;

}
.error-div-img img{
  width: 300px;

}
.error-page a{
  padding: 10px 20px 10px 20px;
  border-radius: 10px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  background-color: #000532;
  color: #fff;

}





.item-single-div{

  background-color: #fff;
  width: 100%;
 
 }


 
.image-magnifier-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 800px; /* Adjust as needed */
  margin: 0 auto;
  max-width: 400px;
  padding: 10px;
 
}

.small-image-container,
.large-image-container {
  position: relative;
  overflow: hidden;
  flex: 1;
  transition: transform 0.1s ease-in-out;
  cursor:crosshair;
  background-color: #fff;
}

.large-image-container { 
  background-color: #fff;
  position: absolute;
  top:150px;
  left:45%;
  transform-origin: top left;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
  z-index: 999; /* Ensure large image is above */
  border: thin #000 1px;
  -webkit-box-shadow: 10px 10px 42px -19px rgba(0,0,0,0.75);
  -moz-box-shadow: 10px 10px 42px -19px rgba(0,0,0,0.75);
  box-shadow: 10px 10px 42px -19px rgba(0,0,0,0.75);
  opacity: 0;
}

.small-image-container img{
  display: block;
  width: 100%;
  height: auto;
  padding:5px;
}
.large-image-container img {
  display: block;
  width: 100%;
  height: 500px;
}

.large-image-container img {
  position: relative; /* Change to absolute positioning */
  transform-origin: top left;
  pointer-events: none;
 
}
.small-image-container:hover {
  background-color: #ccc;
}
.small-image-container:hover + .large-image-container{
  opacity: 1;
  
}


.itm-img-list{
  
  display: flex;
  align-items: center;
  padding: 10px;
 
}

.itm-img-list-container{
  
  display: flex;
  align-items: center;
  margin: 0 auto;
  overflow: hidden;
  scrollbar-width: thin;
  position: relative;
  margin-bottom: 5px;
 
}

.itm-img-list-content{
display: flex;
position: relative; /* Needed for absolute positioning of scroll buttons */
transition: transform 0.3s; /* Add a smooth transition for content movement */
scrollbar-color: #000532 #ebebeb; /* thumb color, track color */
padding-left:25px;
padding-right: 25px;

}
.itm-img-list-content a{
   margin: 2px;    
   padding: 2px;
   border: 1px solid #9b9b9b;
   -webkit-box-shadow: 0px 0px 10px 2px rgba(199,197,199,1);
-moz-box-shadow: 0px 0px 10px 2px rgba(199,197,199,1);
box-shadow: 0px 0px 10px 2px rgba(199,197,199,1);

  }
.itm-img-list-content img{
    width: 75px;
    height: 75px;
    object-fit: cover; 
  }

  .itm-img-list-content .active-image{
 border: 1px solid #38a626;

  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);

  }

  .item-title{
   
    text-align: left;
    color: #000;
    display: flex;
    justify-content: space-between;
    padding: 10px 0 0 0;
    margin: 0 0 0 0;
    
  }
  .item-title h4{
    color: #000;
    align-items:start;
  }
  .item-title h6{
    color: #6d6d6d;
    align-items:start;
  }
  .item-title i{
    color: #004CBD;
    padding-right: 25px;
   
  }



  .gtt-hr{
    border: none; /* Remove the default border */
    height: 2px; /* Set the height of the line */
    background-color: #ffde00; /* Set the desired color */        
    display: flex; 
    align-items: center;
    justify-content: center;
    margin: 0;
  }
  .item-single-div .gtt-hr{
    width: 90%;   
  }

  .item-title .brand{
 color: #6d6d6d;

  }
    .price-list-div{
      
    }
    .price-list-div h5 {
      color: #38a626;
      font-size: 24px;
      font-weight: 600;;
    }

    .price-list-div h5 span{
      color: #38a626;
      font-size: 10px;
      font-weight: 400;
      margin-right: 1px;;
    }

    .was-price span{
     
      font-size: 10px;
      font-weight: 400;
      margin-right: 1px;;
    }

    .was-price{
      color: #000;
      text-decoration: line-through;
      text-decoration-color: #FF5E5E; 
      font-size: 14px;
      font-weight: 500;;
     

    }
  .color-list-div{
    display: flex; 
   
  }
  .color-list-div a{
    margin-left: 7px;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right:2px;
    border: 1px solid #D9D9D9;
    position: relative;
    transition: transform 0.2s;
    border-radius: 3px;

  }

  .color-list-div a:hover {
    transform: translateY(0px);
  }
  
  .color-list-div .arrow-down {
    position: absolute;
    top: -18px;
    left: 50%;
    transform: translateX(-50%);
     opacity: 0;
  
  }

  .color-list-div a.selected .arrow-down {
    position: absolute;
    top: -18px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 1;
  }

  
  .qty-list-div{
    display: flex;   
  }

  .qty-list-div a{
    display: inline-block; /* Change to 'block' if you want it to take up full width */
    text-decoration: none;
    background-color: #d9d9d9;
   
    height: 25px;
    line-height: 25px; /* Vertically center the text */
    padding: 0 10px; /* Adjust padding for left and right */
    font-size: 20px;
    font-weight: 600;
    color: #000;
    margin-right: 2px;
    margin-left: 2px;
  
  }
 
  .qty-list-div  input[type="text"] {
    text-align: center;
    width:15%
  }
  .attr-list-div{
    display: flex;
    flex-direction: column;
  }
.button-list-div{
  width: 100%;
  display: flex;   
  align-items: center;
  justify-content: center;
  margin: 5px;
  
}
.button-list-div a{
  width: 100%;
  display: flex;   
  align-items: center;
  justify-content: center;
  margin: 10px;
  color: #fff;
  height: 35px;
  font-weight: 600;
}
.button-list-div a.addcart{
  background-color: #fabf12;
  color: #000;
  border: 0;
}
.button-list-div a.addcart:hover{
  background-color: #ffde00;
  color: #000;
  
}
.button-list-div a.buynow{
  background-color: #004CBD;
  color: #fff;
  
}
.button-list-div a.buynow:hover{
  background-color: #316cf4;
  color: #fff;
  
}
  .eligible-panel{
    width: 100%;
    color: #000;
    padding: 20px;
    border-radius: 10px;
  }
  .eligible-panel-header{
    font-size: 14px;
    font-weight: 600;
    background-color: #d9d9d9;
    height: 30px;
    border-radius: 10px 10px 0 0 ;
    display: flex; 
    align-items: center;
    justify-content: center;
  }

  .eligible-panel-content{
    font-size: 12px;
    font-weight: 400;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    padding: 10px;
    background-color: #d9d9d936;
    border-radius: 0 0 10px 10px ;

   
  }
  .eligible-panel-content a {
   text-decoration: none;
   text-align: right;
   color: #000;
   margin-bottom: 5px;
   padding: 5px;;
  }
  .eligible-panel-content img {
    margin-left: 10px;;
   }


   .item-hr{
    border: none; /* Remove the default border */
    height: 3px; /* Set the height of the line */
    background-color: #316cf4; /* Set the desired color */
    width: 100%;
    display: flex; 
    align-items: center;
    justify-content: center;
  
  }



.gtt-tab-div{
  color: #000;
}
.gtt-tab-div ul li {
  color: #000;

 
}
.gtt-tab-div ul li a {
  color: #000;
  font-size: 14px;
  font-weight: 500; 
  padding: 5px 15px 5px 15px;
}

.gtt-tab-div ul li a.active{
  color: #000;
 border-radius: 5px;
  background-color: #fabf12;
}


.gtt-tab-div .tab-content {
  display: none;
  padding: 20px;
  
  border-top: none;
  text-align: left;
  padding: 20px;;
}

.gtt-tab-div .tab-content pre {
  font-family: inherit; /* Use the same font as the rest of the page */
  font-size: 12px;
  white-space: pre-wrap;
      word-wrap: break-word;
}

.gtt-tab-div .tab-content.active {
  display: block;
 
}

.like-btn{
  background-color: #a7d71d;
  border-radius: 24px;
  box-shadow: 0px 4px 8px #a7d81e40;
  height: 48px; 
  width: 48px;

  display: flex; 
  align-items: center;
  justify-content: center;
  position: absolute;
  top:250px;
  right:25px;

}
.like-btn i{
 color: #FFFFFF;
 padding: 10px;;
}

.cart-head{
    height: 30px;
    text-align: left;
    color: #004CBD;
    background-color: #fff;
    border-radius: 5px 5px 0 0;    
    padding-left: 5px;
    font-weight: 600;
    display: flex;
    align-items: center; /* Center vertically */
    justify-content: flex-start;
    position: relative;
    width: 100%;
}
.cart-head::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #D9D9D9; /* Change this to your desired line color */
}
.cart-card{
  width: 100%;
  display: flex; 
  align-items: center;
  justify-content:space-around;
  margin-bottom: 5px;
  background-color: #fff;
  border-radius: 5px;;
  text-align: center;
}
.cart-card img{
  padding: 5px;
  width: 100px;
  height: 100px;
  
}
.cart-item-spec{
  text-align: left;
  color: #000;
  margin: 0;
  padding: 10px;
  width: 50%;
  cursor: pointer;
}
.cart-item-spec h5{
 
  margin: 0;
  
}
.cart-item-spec p{
  text-align: left;
  color: #6d6d6d;
  margin: 0;
  font-size: 10px;
}
.highlight-green{
  color: #38a626;
  font-size: 10px;
}

.cart-item-qty{
  padding: 10px;;
  width: 25%;
}
.cart-item-price{
  padding: 10px;;
}
.cart-item-remove p {
 font-size: 10px;
}
.cart-item-remove i {
  color: #FF5E5E;
}
.order-summary{
  background-color: #fff;
  height: 90%;;
  padding: 20px;
  position: relative;
}
.order-summary-item{
  display: flex; 
  align-items: center;
  justify-content:space-between;
  
  margin-bottom: 2px;
}
.order-summary-item-name{

  color: #9b9b9b;
}

.order-summary-item-res{

  color: #000;
  font-size: 12px;;
  font-weight: 600;
}
.order-summary-item-total{
  font-size: 16px;
  font-weight: 600;
  color: #000;
}
.order-summary-item-total-res{
  font-size: 16px;
  font-weight: 600;
  color: #000;
}

.order-summary a.addcart{
  background-color: #fabf12;
  color: #000;
  font-weight: 500;
 
}
.order-summary a.addcart:hover{
  background-color: #ffde00;
  color: #000;
  font-weight: 500;
  
}
.order-summary a.buynow{
  background-color: #004CBD;
  color: #fff;
  
}
.order-summary a.buynow:hover{
  background-color: #316cf4;
  color: #fff;
  
}
.coupon{
  background-color: #004CBD;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  text-decoration: none;
  border-radius: 0 2px 2px 0;
}
.cart-tot-mob{
  margin-top: 2px;
  padding: 10px;;
  display: block;
  width: 100%;
  height: 40px;
  background-color: #FFF;
  margin-bottom: 5px;
  color: #000;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  display: flex; /* Add display: flex */
  align-items:center; /* Center the image vertically */
  justify-content: space-between; /* Center the image horizontally */
  border-radius: 5px;
  -webkit-box-shadow: 2px 2px 13px -1px rgba(89,89,89,1);
  -moz-box-shadow: 2px 2px 13px -1px rgba(89,89,89,1);
  box-shadow: 2px 2px 13px -1px rgba(89,89,89,1);
}

.header-bread{
    display: flex;
    align-items: center; /* Center vertically */
    justify-content: flex-start;

}
.header-bread a{

  text-decoration: none;
  margin-right: 20px;;
}
.account-reg{
  background-color: #fff;
  color: #000;
  border-radius: 10px;
  margin-top: 10px ;
  padding: 10px;
  width: 100%;
  text-align: left;
  min-height: 400px;
  margin-bottom: 100px;
}
.password-toggle {
  display: flex;
  align-items: center;
  position: relative;
}
.eye-icon {
  position: absolute;
  cursor: pointer;
  margin-left: 0px; /* Adjust this value to position the icon correctly */
  z-index: 1;
}
.checkout-head{
  border-bottom: 1px solid #66afe9;
  margin-bottom: 15px;;
  color: #000;
  text-align: left;
}
.paymentcheck{
  background-color: #efefef;
  border-radius: 10px;
  height: 125px;
 padding: 20px;
  position: relative;
  
}

.paymentcheck .cardimg{
   position:absolute;
  bottom: 10px;
  right: 10px;
  font-size: 28px;
  font-weight: 600;

}
.confirm{
  background-color: #fff;
  color: #000;
}
.confirm-main,
.confirm-payment{
  margin-top: 5px;
  color: #000;
  text-align: left;
  
  width: 100%;
  background-color: #FFF;
  border-radius: 10px;
  
  padding: 10;

    display: flex;
    align-items: center; /* Center vertically */
    justify-content: space-between;
    padding: 20;
}
.confirm-address,
.confirm-edit{
  padding: 10px;

}
.confirm-address p{
 margin: 0;
  
}
.confirm-edit a{
  
  text-decoration: none;
}
.confirm-payment .form-check{
 padding-left: 30px;
}
.confirm-payment-main{
  background-color: #fff;
  color: #000;
}
.order-complete-main{
  width: 100%;
  background-color: #fabf12; 
  

}
.order-complete-main-head{
  width: 100%;
  
  min-height: 300px;
  color: #000532;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.order-complete-main-head a{
  
  background-color: #000532;
  color: #FFF;
}
.order-complete-main-head a:hover{
  
  background-color: #FFF;
  color: #000;
  font-weight: 600;
}
.order-complete-main-head i{
  
  color: #fff;
  
  
}
.order-complete-main-dtls{
  background-color: #fff;
  color: #000;
  text-align: left;
  padding: 20px;
  
}
.order-complete-main-dtls p{
  margin: 0;
 
}
.order-complete-footer{
  min-height: 75px;
  background-color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.order-complete-footer a{
  background-color: #000532;
  color: #FFF;
}

.order-complete-footer a:hover{
  
  background-color: #fabf12;
  color: #000;
  font-weight: 600;
}

.login{
  min-height: 600px;
  background-color: #f9f9f9;
 
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-div{
  color: #000;
  text-align: left;
  width: 500px;
 
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 20px #316cf440;

  padding: 25px;
  
}
.signinbtn{
  background-color: #000532;
  color: #fff;
  padding: 5px 20px 5px 20px;
  float: right;
}
.signinbtn:hover{
  background-color: #fabf12;
  color: #000;
} 
.createbtn,
.forgotbtn{
 font-size: 12px;
 font-weight: 500;
}
.user-menu{
  background-color: #D9D9D9;
}
.user-profile{
  background-color: #000532;
  border-radius: 5px;
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content:space-evenly;
  padding: 10px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  
   
}
.user-profile-menu{
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 10px;
  color: #000;

  
}
.user-profile-menu a{
  display: flex;
  align-items: start;
  justify-content: space-between;
   padding: 10px;
  text-decoration: none;
   font-size: 12px;
   font-weight: 400;
   color: #000; 
   width: 100%;
   padding: 10px;
   background-color: #fff;  
   margin-bottom: 1px;
   border-radius: 2px;
}
.user-profile-menu a.activemenu{

   color: #004CBD; 
   font-size: 14px;
   font-weight: 600;
}


.myorder{
  width: 100%;

}
.order-card{
  width: 100%;  
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  color: #000;
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
  
  box-shadow: 0px 4px 20px #316cf440;
  text-align: left;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  margin-bottom: 3px;
}
.order-icon{
  display: block;
  background-color: #fabf12;
  padding: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  color: #000532;
  text-decoration: none;

}
.order-address{
  font-weight: 500;
  font-size: 14px;
  white-space: pre-wrap;
  font: inherit
}
.order-amount{
  width: 15%;
  text-align: left;
}
.order-details-btn{
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  color: #004CBD;
  display: flex;
  align-items: center;

}
.order-status{
  display: flex;
  align-items: end;
  justify-content:space-between;
  flex-direction: column;
 
}
.subscribe-div{

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;;
  background-color: #fff;
  position: relative;

}
.subscribe-1
{ 
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right top, #fabf12, #ebb30e, #dca70a, #cd9b06, #be8f03); 
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
}
.subscribe-text
{
  
  width: 35%;
   color: #000000;
   font-size: 28px;
   font-weight: 600;
   white-space: pre-wrap;
   word-wrap: break-word;  
}

.subscribebtn{
  background-color: #000532;
  color: #fff;
  margin-top: 5px;
}
.subscribebtn:hover{
  background-color: #222222;
  color: #fff;
  margin-top: 5px;
}
.card-gtt{
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
  padding: 10px;
  text-align: left;
  color: #000;
  box-shadow: 0px 2px 0px #85858640;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 2px;

}
.card-gtt a {
  text-decoration: none;
  margin: 2px;
}

.custom-swal-content {
  font-size: 8px; /* Adjust the font size as needed */
}
.return-card{
  text-align: left;
  padding: 20px;;
}
.term-box{
  font-size: 12px;
  font-weight: 400;

}
.delete-account-div{
  min-height: 550px;
  background-color: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #FF5E5E;
}
.delete-account-div a{
 width:50%;
 text-decoration: none;
 font-size: 16px;
 font-weight: 600;
 background-color: #FF5E5E;
 color: #fff;
 padding: 15px;
 border-radius: 5px;
 box-shadow: -5px 8px 23px 0px rgba(0,0,0,0.75);
-webkit-box-shadow: -5px 8px 23px 0px rgba(0,0,0,0.75);
-moz-box-shadow: -5px 8px 23px 0px rgba(0,0,0,0.75);
margin: 5px;
}

.support-call{

 width: 100%;
 background-color: #fff;
 min-height: 250px;
 margin: 2;
 display: flex;
 justify-content: space-around;
 align-items: center;
 
  color: #000;
}
.support-mail{
  
 width: 100%;
 background-color: #dca70a;
 min-height: 250px;
 margin: 2;
 display: flex;
 justify-content: space-around;
 align-items: center;
 
  color: #000;
}

.support-company-profile{
  
  width: 100%;
  background-color: #000532;
  min-height: 250px;
  margin: 2;
  display: flex;
  justify-content: space-around;
  align-items: center;
  
   color: #000;
 }
.support-icon{
  padding: 20px;  
  color: #fff;
  border-radius: 50%;
  display: inline-block;

}
.support-icon.call{
  background-color: #dca70a;
}
.support-icon.mail{
  background-color: #000532;
}
.support-icon.company{
  background-color: #FFF;
  color: #fabf12
}
.support-company-text{
  color: #fff;
}
.support-company-text a{
  background-color: #fabf12;
}
.support-company-text a:hover{
  background-color: #ffde00;
}
.contactus-div{
  background-color: #D9D9D9;
}
.contactus{
  background-color: #D9D9D9;
  color: #000532;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 425px;
 
}

.contactus .address-div .inline-link{

  display: flex;
  align-items: center; 
  
  text-decoration: none;
  gap: 5px;
  color: #000;
  margin-bottom:15px;
  font-size: 14px;
  font-weight: 500;

}

.contactus .address-div img{

  width: 12px;
  height: 12px;

}
.profile-page{
  display: block;
}
.compnay-section{

  color: #000;
  
}
.compnay-section p{

  color: #000;
  text-align: left;
  padding: 10px;
  
}

.card-company{
  padding: 2px;
  background-color: #fff;
 border-radius: 5px;
 box-shadow: 0px 4px 20px rgba(49, 108, 244, 0.25); 
 display: flex;
 justify-content: center;
 align-items: center;
 min-height: 190px;
 margin-bottom: 10px;
}
.card-company h6{
text-align: left;
font-size: 14px;;
}

.card-location{
  min-height: 225px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 4px 20px rgba(49, 108, 244, 0.25); 

  padding: 20px;
  margin-bottom: 5px;
}
.card-location span{
 font-size: 16px;
 font-weight: 600;

  
}

.centered-content {
  text-align: center; /* Center the text horizontally */
}
.card-location-dtls-item{
  display: flex;
  align-items:center;

  margin: 10px;
  text-align: left;
}

.card-location-dtls-item i {
  margin-right: 10px; /* Adjust the value to control the spacing */
}

.brand-img-box-2{
  
  border: 0px none;
  padding: 5px;
  width: 100%;
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}
.brand-img-box-2 img{

  width: 100%;
  max-height: 100px;
  object-fit: cover;
  border-radius: 8px;
  padding: 0;
  margin: 0;
 
}
.bulk{
  color: #000;
  text-align: left;
  padding: 10px;
}
.bulk-desc{
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  text-align: left;
  padding: 10px;
}

/********************************************************/
/*****************MOBILE*******************************/
/********************************************************/


@media (min-width:0) and (max-width: 1200px) {

  .main-body{

    padding-top: 50px;
  }

  .full-bg-head,
  .masterhead,
  .gtt-page-bottom footer  ,
  .left-side-pane,
  .filter-col,
  .item-single-div,
  .item-mastr-img-div,
  .cart-card,
  .profile-page,
  .order-icon,
  .order-address{
   display: none;
  }
  
  .support-mail{  
    width: 100%;
    background-color: #dca70a;
    min-height: 250px;
    margin: 2;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #000;
   }
   .support-mail h4{  
    font-size: 14px;
   }
  .card-gtt .order-address{
    display: block;
  }
  
  .subscribe-text
  {
    
    width: 100%;
     color: #000000;
     font-size: 18px;
     font-weight: 600;
     white-space: pre-wrap;
     word-wrap: break-word;  
  }

  
  .gtt-panel .gtt-panel-header,
  .gtt-panel-center .gtt-panel-header-center {

    background-color: #000532; 
    padding: 10px;
    font-weight: bold;  
    min-height: 30px;
    align-items: left;
    text-align: left;
  }


  /**************************************/
 
/*******************PRODUCT CARD***************************/


.product-carousel-xl .react-multi-carousel-list{
  background: transparent;
  width: 100%;
  
}

.product-card-gtt
{
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 2px 0px #85858640;
  height: 306px;
  min-width:150px;
  max-width: 228px;
  margin: 15px;

}
.product-card-gtt .product-body-gtt
{
  position: relative;
  padding: 5px;
  width: 100%;

}
.product-body-gtt img{

  width: 100%;
  max-height: 100px;
  object-fit: scale-down;
  border-radius: 8px;
  padding: 0;
  margin: 0;
 
}




.product-details-gtt{

  position: relative;
}

.product-details-gtt .addcartbtn{

  position: absolute;
  top: -10px;
  right: 0;
  text-decoration: none;
  padding: 1px 5px 1px 5px;
  border: 1px solid #316cf4;
  border-radius: 25px;
  font-size: 10px;
  font-weight: 500;
}
.product-details-gtt .addcartbtn:hover{

 background-color: #004CBD;
 color: #fff;
}

.product-details-gtt .addcartbtn img{

   height: 20px;
   width: 20px;
}


.product-body-gtt .product-brand-text{
  text-align: left;
  font-size: 8px;
  font-weight: 500;
  color: #9b9b9b;
  margin: 0 0 5px 0;  
  padding: 0;

}


.product-body-gtt .product-title {
  margin: 0; 
  font-size: 12px;
  font-weight: 600;
  margin: 0;
  color: #222222;
  text-align: left;
  padding: 0;
  margin: 5px 0 0 0;  
  white-space: nowrap; /* Prevent text from wrapping to multiple lines */
  overflow: hidden; /* Hide any overflowing content */
  text-overflow: ellipsis; 
}
.product-body-gtt .product-description {
  text-align: left;
  font-size: 8px;
  font-weight: 600;
  color: #9b9b9b;
  margin: 0;

  height: 35px; /* Adjust the height as needed to fit two lines */
  width: 100%;
  overflow: hidden;
  display: -webkit-box; /* Set display to a webkit box */
  -webkit-line-clamp: 2; /* Show up to 2 lines */
  -webkit-box-orient: vertical; /* Set vertical orientation */
  padding: 0;
  

}


.product-body-gtt .product-attribute-gtt{

   
  display: flex; /* Add display: flex */
  align-items:center; /* Center the image vertically */
  justify-content: space-between; /* Center the image horizontally */
  margin:0;
  padding: 0;
  margin-bottom: 2px;

}

.product-body-gtt .product-attribute-gtt .price-tag {
  text-decoration: none;
  text-align: right;
}
.product-body-gtt .product-attribute-gtt .price-tag .was-price{
  text-align: right;
  font-size: 10px;
  font-weight: 600;
  text-decoration: line-through;
  color: #9b9b9b;
  margin: 0;


}
.product-body-gtt .product-attribute-gtt .price-tag .current-price{

  text-align: right;
  color: #38a626; 
  font-size: 14px;
  font-weight: 600;
  margin: 0;

}
 .currency-tag {

  text-align: right;
  color: #38a626; 
  font-size: 8px;
  font-weight: 600;
  margin: 0;

}
.product-body-gtt .product-attribute-gtt .price-tag .discount-tag {
  color: #fff; 
  font-size: 8px;
  font-weight: 600; 
  background-color: #fabf12;
  border-radius: 15px;
  padding: 2px;
  text-align: right;
}

.product-body-gtt .product-attribute-gtt .gtt-facility{

  font-size: 8px;
  font-weight: 500;
  color: #316cf4;
  text-align: left;
  margin: 0;
}

.product-body-gtt .product-attribute-gtt .gtt-facility .gtt-waranty{

  font-size: 8px;
  font-weight: 600;
  color: #000;
  margin: 0;
  margin-bottom: 2px;
}



.product-body-gtt .product-attribute-gtt .gtt-facility .gtt-waranty .warranty-tag {

  font-size: 8px;
  font-weight: 400;
  font-style: normal;
  
}

.product-body-gtt .product-attribute-gtt p {
  /* Add this rule to remove spacing for <p> tags within .product-attribute-gtt */
  margin: 0;
}

.product-carousel-xl .react-multiple-carousel__arrow{
  display: none;
 
 }
 .product-carousel-xl:hover .react-multiple-carousel__arrow{
   display: block; 
  }
 


/*******************PRODUCT CARD END ***************************/


  .input-container-mob{

   display: block; 

  justify-content: center; /* Center the content horizontally */
  align-items: center; /* Center the content vertically */
  width: 100%;
/* Adjust the height as needed */
  background-color:transparent;
  margin-top: 0px; /* Adjust the margin to create space below the fixed menu */
  position: relative;


/*   
   display: block;
   z-index: 999;
   position: fixed;
   top: 50px;
   left: 50%;
   transform: translateX(-50%);
   background-color: rgba(0, 0, 0, .05);
   padding: 10px;
   width: 100vw; 
   height: 100vh;  */
  }
  .input-group-gtt{
    display: flex;
    align-items: center;

    max-width: 100%; /* Adjust the width as needed */
    border: 0px solid #ccc;
    border-radius: 4px;
    padding: 5px;
  }
  .input-group-gtt input {
    flex: 1; /* Fill remaining space within the group */
    border: none;
    outline: none;
    width: 100%;;
  }


  .input-group-gtt .input-group-addon {
    margin-left: 8px; /* Adjust the spacing as needed */
    color: #fabf12;
  }

  .input-group-gtt {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .input-container-mob .search-form-control{
   
    width: 100%;
    height: 40px;
    padding-left: 30px ;
    border: 1px solid #ccc;

    
  }

  .input-container-mob .icon-mob{
    display: block;
   

    transform: translateY(-50%);
    color: #000532;
    padding: 2px;
  }
  
  /*zIndex:999,position: 'fixed', top: '50px', left: '50%', transform: 'translateX(-50%)', backgroundColor:'rgba(0, 0, 0, 0.5)', padding: '10px', width:'100%',height:'100%' */


  
/***************CATGROY ROUND *****************************/
.category-img-box{

  border: 0px none;
  height: 110px;
  width: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
}

.category-img-box .catgory-ellipse{

  background-color: #d9d9d9;
  border-radius: 49px;
  height: 80px;
  width: 80px;
  display: flex; /* Add display: flex */
  align-items: center; /* Center the image vertically */
  justify-content: center; /* Center the image horizontally */
  margin-bottom: 20px;
  
}


.category-img-box .catgory-ellipse img {
  height: 90px;
  object-fit: cover;
  
}

.category-img-box a span  {
  text-decoration: none;
  position: absolute;
  bottom:0;
  text-align: center;
  color: #000532;
  font-size: 10px;
  font-weight: 700;


}






  .fixed-background {

    background-size: cover;
    background-position: top center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 400px;
    z-index: -1;
    /* Place the background behind other content */
    background: linear-gradient(180deg, #000532 0%, #004CBD 100%);
  }

  .gtt-container {
    width: 100%;  
    max-width: 600px;  
    box-sizing: border-box;
    padding-top:50px;
   
  }

  .masterhead{
    height: 60px;
  }
  .masterhead .item{   
      display: none;
    }
 
  .mob-masterhead{
   
  
    display: flex;
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #000532; /* Optional: You can set the background color as needed */
    z-index: 1000;
 
    
  }

 .mob-masterhead .companylogo {
 
  height: 100%;
  }
 .mob-masterhead .item {   
    margin: 5px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;    
  }

  .mob-icon a{
     color:#fff;
     font-size:18px;         
  }

      .mob-masterhead div:first-child {
        width: 10%;
      }
      .mob-masterhead div:nth-child(2) {
        width: 70%;
    }
    .mob-masterhead div:nth-child(3) {
      width: 10%;
    }
    .mob-masterhead div:nth-child(4) {
      width: 10%;
    }






    .gtt-master-carousel{
      width: 100%;
    }

    .gtt-master-carousel  .gtt-carousel .half-picture img{
      max-height:200px;
     
    }

    
  .gtt-master-carousel .gtt-carousel-head h5{
    font-size: 12px;
  }
  .gtt-master-carousel .gtt-carousel-head h4{
    font-size: 14px;
  }
  .gtt-master-carousel .gtt-carousel-head h6{
    font-size: 10px;
  }

  .gtt-carousel-two {
   display: none;
  
  }

  /*******MOBILE BOTTOM MENU**********************************/


  .mob-bottom-menu-bgdiv{
    display: block;
    width: 100%;
    height:100px; 
    background-color:transparent;
   }

  .mob-bottom-menu{
    z-index:9999;
    display:block;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height:75px; 
    background-color: #FFFFFF;
    border-radius: 12px 12px 0px 0px;
    
   }
   .mob-bottom-menu .mob-menu{
    padding: 10px;
    margin: 5px;   
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  
   }
   .mob-bottom-menu .mob-menu a {
    
    text-decoration: none;
    color: #316cf4;
    font-size: 10px;
 
  }
   .mob-bottom-menu .mob-menu a img{
    
     height: 20px;
  
   }
 


   /*******ADS LIST**********************************/

   .adslist-master{
    display: none;
  }

  .adslist-master-mob{
    display: block;
  }


  .card-gtt-1{
     width: 100%;
  height: 100%;
    background: #D9D9D9;
    box-shadow: 0px 4px 20px rgba(49, 108, 244, 0.25); 
    border-radius: 15px
  }
   


  .productlist-head-tag .symbol-tag{
    width: 50%;   
    font-size: 12px;
    font-weight: 600;           
  }
 
  .productlist-head-tag .symbol-seemore{    
    width: 50%; 
  }



  .gtt-bottombrand h4{
   
    background-color: #000532;
    width: 100%;
    color: 'white';
    font-size: 12px;
    font-weight: '500';
    letter-spacing: 1.28;
    word-wrap: 'break-word';
    text-align: center;
    padding:2px;
    text-align: center;
  
  }


  .brand-multi-slide{

    padding: 5px;
  
  }
  .brand-img-box{
  
    border: 0px none;
    padding: 5px;
    width: 75px;
    border-radius: 5px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  
  .brand-multi-slide .react-multiple-carousel__arrow{
    display: none;
   
   }
   .brand-multi-slide:hover .react-multiple-carousel__arrow{
     display: block; 
    }

    .img-banner{
    display: flex; /* Add display: flex */
    align-items: center; /* Center the image vertically */
    justify-content: space-between; /* Center the image horizontally */
    overflow-x: auto; /* Enable horizontal scrolling if content overflows */
    white-space: nowrap; 
  
  }
  .img-banner{
    margin: 0 5px; /* Add some horizontal spacing between images */
    max-width: 100%; 

  
  }


  .pagination-nav{
    background-color: #ffffff;
    border-radius: 26px;
    box-shadow: 0px 4px 10px #316cf440;
    height: 30px;
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }


  .gtt-itemshow-div .form-select{   
    font-size: 10px;
  }


  .gtt-badge-white{
   
    height: 15px;
    background-color: #fff;
    color:#222222;
    border-radius: 20px;
    font-size: 8px;
    font-weight: 300;
    padding: 2px;;
    margin-right: 2px;
  
  }
  .gtt-badge-clear{
    font-size: 8px;
    font-weight: 400;
    cursor: pointer;
  }
  
  .gtt-panel .gtt-panel-header,
  .gtt-panel-center .gtt-panel-header-center {
   display: none;
  }
  


  .off-canvas-container {
    width: 60%;
    height: 100vh;
    overflow: hidden;
    transition: transform 0.3s ease;
    background-color: #fff;
    z-index: 1000;
    position: fixed;
    top: 0;
    right: 0;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
  }
  
  .off-canvas-container .open {
    transform: translateX(-150px); /* Adjust the value as needed */
  }
  
  .toggle-button {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 666;  
    color: #000532;
  }
  
  .off-canvas-menu {
    position: relative;   
    background-color:transparent;
    color: #000;
    
  }

  .off-canvas-menu ul{
   width: 100%;
   padding: 0;
  } 
  .off-canvas-menu li{
    width: 100%;
    padding:10px 0px 10px 0px;
    text-align: left;   
    list-style: none;
    cursor: pointer;
   } 

   .off-canvas-menu li:hover a{
     color: #000;
     font-weight: 600;
     width: 100%;


   } 

   .off-canvas-menu li a{
     text-decoration: none;
     font-size: 12px;
     font-weight: 500;
     padding-left: 25px;
     color: #000;
     color: #316cf4;
   } 
    .mob-profile{
     
      display: flex;
      justify-content: center; /* Horizontally center */
      align-items: center; 
      flex-direction: column;
      top: 0;
      background-color: #fabf12;
      height: 125px;
       background: linear-gradient(to right, #000532 5%, #fabf12 5%, #fabf12 100%);
    }
    .mob-profile a{
      text-decoration: none;
      color: #000532;
      font-size: 14px;
      font-weight: 500;
    }


    
 .gtt-badge {
  position: absolute;
 top: -15px; /* Adjust this value to position the badge vertically */
 right: -20px; /* Adjust this value to position the badge horizontally */
 display: inline-block;
 padding: 5px 10px;
 background-color: #fabf12; /* Background color of the badge */
 color: white; /* Text color of the badge */
 border-radius: 50%;
}
.item-single-div-mob{
  display: block;
}
.item-mastr-img-div-mob{
  display: block;
  background-color: #fff;
}

  .itm-img-list-content-mob{
    display: flex;
    align-items: center;
    margin: 0 auto;
    overflow: hidden;
    scrollbar-width: thin;
    position: relative;
    margin-bottom: 5px;
    width: 100%; /* Set the desired width */
    overflow-x: auto; /* Enable horizontal scrolling */
    white-space: nowrap;
  }
  .itm-img-list-content-mob img{
    max-width:200px;
    margin: 10px;
  }
.item-title{
  background-color: #f9f9f9;
  padding: 10px;;
}
.item-title h6{
  font-size: 12px;;

}
.item-details-mob{
  background-color: #f9f9f9;
}
.price-list-div{
  padding-top: 25px;;
  text-align: right;
 
}
.mob-addcart-div{
    position: fixed;
  bottom: 75px;
  z-index: 999;
  padding: 10px;
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   
}
.mob-addcart-div .gtt-round-btn{
    height: 48px;
    width: 48px;
    background-color: #316cf4;
    border-radius: 24px;
    box-shadow: 0px 4px 8px #316cf440;   
    color: #fff;
    padding: 10px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px;

  }
  .mob-addcart-div .flat-btn{
    width: 50%;
    border-radius: 25px;
  box-shadow: 0px 4px 8px #316cf440;
  height: 48px;
  background-color: #316cf4;
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  }
  .attr-list-div{
    font-size: 10px;;
  }
  
  .eligible-mob {
    text-align: left;
    padding: 20px;
    width: 95%;
    border-radius: 25px;
    border: 1px solid #004CBD;
    margin: 10px
  }
.eligible-mob a {
  text-decoration: none;
  text-align: right;
  color: #000;
  

 }
 .eligible-mob-col{
  height: 30px;
  text-align: center;

 }
 .eligible-mob img {
   margin-left: 10px;;
  }



.cart-card-mob{
  width: 100%;
  display: flex; 
  align-items: center;
  justify-content:flex-start;
  margin-bottom: 5px;
  background-color: #fff;
  border-radius: 5px;;
  box-shadow: 0px 4px 8px #316cf433;
}
.cart-card-mob img{
  padding: 5px;
  width: 75px;
  height: 75px;
  
}
.cart-item-spec{
  text-align: left;
  color: #000;
  margin: 0;
  padding: 5px;
  width: 50%;
}
.cart-item-spec h5{
 
  margin: 0;
  font-size: 12px;
  
}
.cart-item-spec p{
  text-align: left;
  color: #6d6d6d;
  margin: 0;
  font-size: 8px;
}
.highlight-green{
  color: #38a626;
  font-size: 10px;
}


.price-list-div h5 {
  color: #38a626;
  font-size: 14px;
  font-weight: 600;;
}

.price-list-div h5 span{
  color: #38a626;
  font-size: 10px;
  font-weight: 400;
  margin-right: 1px;;
}

.was-price span{
 
  font-size: 10px;
  font-weight: 400;
  margin-right: 1px;;
}

.was-price{
  color: #000;
  text-decoration: line-through;
  text-decoration-color: #FF5E5E; 
  font-size: 12px;
  font-weight: 500;;
 

}






.cart-item-remove span {
  margin: 0; /* Remove default margin from <p> */
  margin-right: 5px; /* Add space between text and icon */
  color:#9b9b9b; 
  font-size: 10px;/* Text color */
}

.cart-item-remove a {  
  text-decoration: none;
  color: #fff; 
  background-color: #FF5E5E;
  padding: 5px 5px; /* Adjust padding as needed */
  border-radius: 5px;
  text-align: center; /* Center the content horizontally */
  display: inline-flex; /* Display as a flexbox container */
  align-items: center;
  width: 25px;
  height: 25px;
}
.cart-item-remove i {
  color: #FFF;
  font-size: 14px; 
  margin-right: 5px
}

.header-bread{
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: center;
 

}
.header-bread a{

  text-decoration: none;
  text-align: center;
  margin-bottom: 10px;
}
.checkout-head h6{
  font-size: 12px;
  font-weight: 500;
 
}

.signinbtn{
  background-color: #000532;
  color: #FFF;
  padding: 5px 20px 5px 20px;
  float: none;
   width: 100%;
}

}






/********************************************************/
/*****************DESKTOP*******************************/
/********************************************************/
@media (min-width: 1200px) {
  .gtt-container {
    max-width: 1200px;
  }


  .gtt-container {
    max-width: 1200px;
  }
  

  .gtt-panel .gtt-panel-header,
  .gtt-panel-center .gtt-panel-header-center {
    background-color: #000532;
    border-bottom: 1px solid #ccc;
    padding: 10px;
    font-weight: bold;
    border-radius: 10px  10px 0 0px;
    min-height: 40px;
    align-items: left;
    text-align: left;
  }
  



}


